<template>
  <div>
    <b-overlay :show="show2" rounded="sm" no-fade>
      <b-row>
        <b-col lg="12" class="align-items-center mr-auto">
          <b-card no-body>
            <!-- title and dropdown -->
            <b-card-header class="pb-0">
              <b-dropdown
                no-caret
                right
                :text="datelabel"
                variant="transparent"
                class="chart-dropdown"
                toggle-class="p-50"
                size="sm"
              >
                <b-dropdown-item @click="selectdate(0)">วันนี้</b-dropdown-item>
                <b-dropdown-item @click="selectdate(1)"
                  >เมื่อวาน</b-dropdown-item
                >
                <b-dropdown-item @click="selectdate(2)"
                  >7 วันที่ผ่านมา</b-dropdown-item
                >
                <b-dropdown-item @click="selectdate(3)"
                  >30 วันที่ผ่านมา</b-dropdown-item
                >
                <b-dropdown-item @click="selectdate(4)"
                  >เดือนนี้</b-dropdown-item
                >
                <b-dropdown-item @click="selectdate(5)"
                  >กำหนดเอง</b-dropdown-item
                >
              </b-dropdown>
            </b-card-header>
            <!--/ title and dropdown -->

            <b-form-group v-if="customdate">
              <h5>ระบุช่วงวันที่</h5>
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="{ mode: 'range' }"
                @input="onDateChange"
              />
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3" sm="6">
          <statistic-card-horizontal
            icon="EyeIcon"
            :statistic="alldata.traffic"
            statistic-title="TOTAL TRAFIC"
          />
        </b-col>

        <b-col lg="3" sm="6">
          <statistic-card-horizontal
            icon="EyeIcon"
            :statistic="alldata.avg_traffic"
            statistic-title="AVG. TRAFFIC PER CONNECTION"
          />
        </b-col>

        <b-col lg="3" sm="6">
          <statistic-card-horizontal
            icon="EyeIcon"
            :statistic="alldata.time_human"
            statistic-title="TOTAL CONNECTION TIME"
          />
        </b-col>

        <b-col lg="3" sm="6">
          <statistic-card-horizontal
            icon="EyeIcon"
            :statistic="alldata.avg"
            statistic-title="AVG. CONNECTION TIME PER USER"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BTable,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormGroup,
  BFormSelect,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";

import CardAnalyticSessionsByDevice from "./CardAnalyticSessionsByDevice.vue";
import CardAnalyticProductOrders from "./CardAnalyticProductOrders.vue";
import CardAnalyticCustomers from "./CardAnalyticCustomers.vue";
import CardAnalyticSalesRadarChart from "./CardAnalyticSalesRadarChart.vue";
import CardAnalyticSupportTracker from "./CardAnalyticSupportTracker.vue";
import CardAnalyticRevenue from "./CardAnalyticRevenue.vue";
import CardAnalyticGoalOverview from "./CardAnalyticGoalOverview.vue";
import CardAnalyticAvgSessions from "./CardAnalyticAvgSessions.vue";
import CardAnalyticSaleLineChart from "./CardAnalyticSaleLineChart.vue";
import CardAnalyticRevenueReport from "./CardAnalyticRevenueReport.vue";
import CardAnalyticEarningsChart from "./CardAnalyticEarningsChart.vue";
import api from "@/api";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
//import ChartjsComponentBarChart from '../../charts-and-maps/charts/chartjs/charts-components/ChartjsComponentBarChart.vue'
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import UserTable from "./usertable.vue";

export default {
  components: {
    BRow,
    BCol,
    flatPickr,
    BDropdownItem,
    BDropdown,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormGroup,
    BFormSelect,
    CardAnalyticSessionsByDevice,
    CardAnalyticProductOrders,
    CardAnalyticCustomers,
    CardAnalyticSalesRadarChart,
    CardAnalyticSupportTracker,
    CardAnalyticRevenue,
    CardAnalyticGoalOverview,
    CardAnalyticAvgSessions,
    CardAnalyticSaleLineChart,
    CardAnalyticRevenueReport,
    CardAnalyticEarningsChart,
    StatisticCardHorizontal,
    VueApexCharts,
    UserTable,
    BTable,
    BOverlay,
  },
  data() {
    return {
      show2: false,
      customdate: false,
      perPage: 10,
      pageOptions: [5, 10, 15],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "title", label: "Venue", sortable: false },
        { key: "count", label: "Connections", sortable: true },
      ],
      /* eslint-disable global-require */
      items: [],
      loaded: false,
      rangeDate: null,

      daterange: 2,
      datelabel: "7 วันที่ผ่านมา",
      alldata: "",
      timeofday: {
        series: [
          {
            name: "Users",
            data: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0,
            ],
          },
        ],
        chartOptions: {
          chart: {
            sparkline: { enabled: false },
            toolbar: { show: false },
          },
          states: {
            hover: {
              filter: "none",
            },
          },

          grid: {
            borderColor: "#ebe9f1",
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          colors: [
            "#ebf0f7",
            "#ebf0f7",
            $themeColors.primary,
            "#ebf0f7",
            "#ebf0f7",
            "#ebf0f7",
          ],
          plotOptions: {
            bar: {
              columnWidth: "45%",
              distributed: true,
              endingShape: "rounded",
            },
          },

          xaxis: {
            categories: [
              "",
              "1:00AM",
              "",
              "3:00AM",
              "",
              "5:00AM",
              "",
              "7:00AM",
              "",
              "9:00AM",
              "",
              "11:00AM",
              "",
              "1:00PM",
              "",
              "3:00PM",
              "",
              "5:00PM",
              "",
              "7:00PM",
              "",
              "9:00PM",
              "",
              "11:00PM",
              "",
            ],

            tickPlacement: "on",
          },
          yaxis: {
            tickAmount: 5,
          },
          tooltip: {
            x: { show: true },
          },
        },
      },

      dayofweek: {
        series: [
          {
            name: "Users",
            data: [0, 0, 0, 0, 0, 0, 0],
          },
        ],
        chartOptions: {
          chart: {
            sparkline: { enabled: false },
            toolbar: { show: false },
          },
          states: {
            hover: {
              filter: "none",
            },
          },

          grid: {
            borderColor: "#ebe9f1",
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          colors: [
            "#ebf0f7",
            "#ebf0f7",
            $themeColors.primary,
            "#ebf0f7",
            "#ebf0f7",
            "#ebf0f7",
          ],
          plotOptions: {
            bar: {
              columnWidth: "45%",
              distributed: true,
              endingShape: "rounded",
            },
          },

          xaxis: {
            categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],

            tickPlacement: "on",
          },
          yaxis: {
            tickAmount: 5,
          },
          tooltip: {
            x: { show: true },
          },
        },
      },
    };
  },
  beforeMount() {
    this.selectdate(2);
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onDateChange() {
      console.log(this.rangeDate);
      if (this.rangeDate.search("to") > 0) {
        const myArr = this.rangeDate.split(" to ");
        this.fromDate = myArr[0];
        this.toDate = myArr[1];
        this.$nextTick(() => {
          this.loadData();
        });
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectdate(daterange) {
      console.log(daterange);
      this.loaded = false;
      this.daterange = daterange;
      // alert(daterange);
      var today = this.$moment();

      //  console.log(daterange);
      if (daterange == 0) {
        this.customdate = false;
        this.datelabel = "วันนี้";
        this.fromDate = today.format("YYYY-MM-DD");
        this.toDate = today.format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loadData();
        });
      } else if (daterange == 1) {
        this.customdate = false;
        this.datelabel = "เมื่อวาน";

        this.fromDate = today.subtract(1, "days").format("YYYY-MM-DD");
        this.toDate = this.fromDate;
        this.$nextTick(() => {
          this.loadData();
        });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 2) {
        this.datelabel = "7 วันที่ผ่านมา";

        this.fromDate = today.subtract(7, "days").format("YYYY-MM-DD");
        this.toDate = this.$moment().format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loadData();
        });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 3) {
        this.customdate = false;

        this.datelabel = "30 วันที่ผ่านมา";

        this.fromDate = today.subtract(30, "days").format("YYYY-MM-DD");
        this.toDate = this.$moment().format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loadData();
        });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 4) {
        this.customdate = false;

        this.datelabel = "เดือนนี้";

        this.fromDate = this.$moment().startOf("month").format("YYYY-MM-DD");
        this.toDate = this.$moment().endOf("month").format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loadData();
        });
      } else if (daterange == 5) {
        this.customdate = true;
        this.datelabel = "กำหนดเอง";

        // this.fromDate = this.$moment().startOf('month').format('YYYY-MM-DD');
        // this.toDate = this.$moment().endOf('month').format('YYYY-MM-DD');
        //  this.$nextTick(() => {
        //    this.loadData();
        //})
        this.fromDate = null;
        this.toDate = null;

        console.log(this.fromDate);
        console.log(this.toDate);
      } else {
        this.fromDate = null;
        this.toDate = null;
      }

      // console.log(currentDateWithFormat);
    },

    loadData() {
      this.show2 = true;
      const params = {
        fromDate: this.fromDate,
        toDate: this.toDate,
      };

        // setTimeout(() => this.show2 = false, 2000);
      api.post("/report/traffics", params).then((response) => {
        console.log(response.data);
        this.alldata = response.data;
        this.show2 = false;
        this.loaded = true;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
